import React from "react"
import SEO from "../components/seo"

import Layout, { Section } from "../components/layout"
import { SectionHeading, ParagraphText } from "../components/typography"
import { Link } from "gatsby"
import styled from "styled-components"

const HomeLink = styled(Link)`
  padding: 3px;
  text-decoration: underline;
`

export default function Manifesto() {
  return (
    <Layout IsDarkTheme>
      <SEO title="BlueTrace Manifesto" />
      <Section>
        <div className="container">
          <HomeLink to="/"><ParagraphText> BACK TO HOME</ParagraphText></HomeLink>
          <SectionHeading>Our Manifesto</SectionHeading>
          <ParagraphText>
            TraceTogether's model of community-driven contact tracing is the
            first nationwide deployment of a Bluetooth contact tracing solution
            in the world. We are hopeful that TraceTogether can bring benefits
            to communities around the world in our common fight against
            COVID-19. We are heartened by the interest from governments,
            non-profits and companies looking to adapt TraceTogether for their
            communities.
          </ParagraphText>

          <ParagraphText>
            TraceTogether is built on the BlueTrace protocol, designed by the{" "}
            <a href="https://hive.gov.sg">Government Digital Services</a> team
            at{" "}
            <a href="https://tech.gov.sg">
              Government Technology Agency of Singapore
            </a>
            . Mobile apps and wearables (in the future) that deploy the
            BlueTrace protocol are able to blend decentralised and centralised
            models of contact tracing. The collection and logging of
            encounter/proximity data between devices that implement BlueTrace is
            done in a peer-to-peer, decentralised fashion, to preserve privacy.
            At the same time, the analysis and the provision of epidemic control
            guidance is done centrally by a trusted public health authority,
            committed to driving adoption. Sovereignty is respected through a
            federated model among a network of participating countries and
            public health authorities.
          </ParagraphText>

          <ParagraphText>
            COVID-19 and other novel viruses do not respect national boundaries.
            Neither should humanity's response. In a globalised world, with high
            volumes of international travel (until very recently{" "}
            <span role="img" aria-label="sad emoji">
              😢
            </span>
            ), any decentralised contact tracing solution will need mass
            adoption to maximise network effects. We believe that TraceTogether
            and its sister implementations should be inter-operable, and that's
            what we're building towards.
          </ParagraphText>

          <ParagraphText>
            These are big dreams, and success is not guaranteed. There are both
            technical and non-technical challenges that stand in the way.
          </ParagraphText>

          <ParagraphText>
            We are working around the clock to finalise our protocol reference
            documents and reference implementation, to open source what we have
            built, so that others may deploy their own flavours of TraceTogether
            - each implementing the BlueTrace protocol. We appreciate your
            patience in the meantime.
          </ParagraphText>

          <ParagraphText>
            Please stay tuned for more updates. You may also reach us for
            specific queries pertaining to collaborations and partnerships at{" "}
            <a href="mailto:partnerships@tracetogether.gov.sg">
              partnerships@tracetogether.gov.sg
            </a>
          </ParagraphText>

          <ParagraphText>
            When we TraceTogether, we are safer together.
          </ParagraphText>

          <ParagraphText>
            - <a href="https://hive.gov.sg">Team TraceTogether</a>
            <br />
            <em>
              A team of thinkers and doers working with code, pixels and people
            </em>
            <br />
            <em>
              Helping citizens one line of code, one pixel, one user interview,
              and one idea at a time
            </em>
            <br />
            <em>#techforpublicgood</em>
          </ParagraphText>
        </div>
      </Section>
    </Layout>
  )
}
