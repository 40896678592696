import styled from "styled-components"
import { bt_darkblue } from "./color"

export const SectionHeading = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 2em;
  line-height: 1.25em;
  color: ${props => (props.light ? "white" : bt_darkblue)};
  margin-top: .5rem;
  margin-bottom: 1.5rem;
`

export const SectionSubHeading = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 1.2em;
  line-height: 1em;
  color: ${props => (props.light ? "white" : bt_darkblue)};
  margin-top: .5rem;
  margin-bottom: 1rem;
`

export const ParagraphText = styled.p`
  font-style: normal;
  font-weight: normal;
  line-height: 1.4em;
  color: ${props => (props.light ? "white" : bt_darkblue)};
  margin-bottom: 1em;
`
